<template>
  <!-- Dialog component for adding a new sales opportunity record -->
  <base-dialog title="Add sales opportunity" :open="showAddDialog">
    <template #actions>
      <a @click="closeAddSalesOpportunityDialog">X</a>
    </template>
    <template #default>
      <create-sales-opportunity
        @task-completed="closeAddSalesOpportunityDialog"
      ></create-sales-opportunity>
    </template>
  </base-dialog>

  <!-- Dialog component for editing a sales opportunity record -->
  <base-dialog title="Edit sales opportunity" :open="showEditDialog">
    <template #actions>
      <a @click="closeEditSalesOpportunityDialog">X</a>
    </template>
    <template #default>
      <edit-sales-opportunity
        @task-completed="closeEditSalesOpportunityDialog"
        :sales-opportunity-id="editSalesOpportunityId"
      ></edit-sales-opportunity>
    </template>
  </base-dialog>

  <!-- List all exsiting sales opportunities -->
  <items-table
    title="Sales Opportunities"
    add-button-caption="New sales opportunity"
    :add-function="showCreateSalesOpportunityDialog"
    :show-add-button="isSalesOpportunityManager"
  >
    <sales-opportunities-list
      @edit-sales-opportunity="editItem"
    ></sales-opportunities-list>
  </items-table>
</template>

<script>
import { ref, defineAsyncComponent } from "vue";

const CreateSalesOpportunity = defineAsyncComponent(() =>
  import("../../components/salesopportunities/CreateSalesOpportunity.vue")
);
const EditSalesOpportunity = defineAsyncComponent(() =>
  import("../../components/salesopportunities/EditSalesOpportunity.vue")
);

import SalesOpportunitiesList from "../../components/salesopportunities/SalesOpportunitiesList.vue";

import useRoles from "../../hooks/roles";

export default {
  components: {
    SalesOpportunitiesList,
    CreateSalesOpportunity,
    EditSalesOpportunity,
  },
  setup() {
    const showAddDialog = ref(false);
    const showEditDialog = ref(false);
    const editSalesOpportunityId = ref(null);

    function editItem(salesOpportunityId) {
      editSalesOpportunityId.value = parseInt(salesOpportunityId);
      showEditDialog.value = true;
    }

    function closeEditSalesOpportunityDialog() {
      showEditDialog.value = false;
      editSalesOpportunityId.value = null;
    }

    function closeAddSalesOpportunityDialog() {
      showAddDialog.value = false;
      showEditDialog.value = false;
    }

    function showCreateSalesOpportunityDialog() {
      showAddDialog.value = true;
    }

    const { isSalesOpportunityManager } = useRoles();

    return {
      showAddDialog,
      showEditDialog,
      editSalesOpportunityId,
      editItem,
      closeEditSalesOpportunityDialog,
      closeAddSalesOpportunityDialog,
      isSalesOpportunityManager,
      showCreateSalesOpportunityDialog,
    };
  },
};
</script>
